import React, { useState } from 'react';

function useError() {
  const [error, setError] = useState(null);
  function logError(err) {
    if (!err) {
      setError(null);
      return;
    }
    // eslint-disable-next-line no-console
    console.error(err);
    setError(err.message);
  }
  const errorAlert = error && (
    <div className="alert alert-danger alert-dismissible" role="alert">
      {error}
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={() => { setError(null); }}
      />
    </div>
  );
  return [errorAlert, logError];
}

export default useError;
