/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RegLine from './RegLine';

const CANCELED_INIT = Symbol('No action');
const CANCELED_OK = Symbol('Cancel success');
const CANCELED_FAILED = Symbol('Cancel error');

function RegList(props) {
  const { registrations, ownReg, cancelRegistration } = props;
  const canCancel = ownReg && registrations.some((reg) => ownReg[reg.id]);
  const [canceled, setCanceled] = useState(null);
  function handleCancel(id) {
    cancelRegistration(id)
      .then((deletedRows) => {
        setCanceled(deletedRows === 0 ? CANCELED_FAILED : CANCELED_OK);
        window.setTimeout(() => {
          setCanceled(CANCELED_INIT);
        }, 3000);
      });
  }
  return (
    <div>
      <p>
        <b>Nu ingeschreven</b>
        {' '}
        (
        {registrations.length}
        , op volgorde van achternaam):
      </p>
      {canceled === CANCELED_OK
        && <div className="alert alert-success" role="alert">Je inschrijving is verwijderd.</div>}
      {canceled === CANCELED_FAILED
        && <div className="alert alert-danger" role="alert">Je inschrijving kon niet verwijderd worden.</div>}
      {canCancel && (
        <p className="text-muted">
          {'Klik op '}
          <span className="text-danger">x</span>
          {' om jezelf uit te schrijven.'}
        </p>
      )}
      <table className="table table-bordered table-striped table-sm w-auto">
        <thead>
          <tr className="text-center">
            <th>#</th>
            <th>voornaam</th>
            <th>achternaam</th>
            <th>cat.</th>
            <th>afstand</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {registrations.map((reg, idx) => (
            <RegLine
              key={reg.id}
              idx={idx}
              {...reg}
              regcode={ownReg[reg.id]}
              onCancel={() => handleCancel(reg.id)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

RegList.propTypes = {
  registrations: PropTypes.array.isRequired,
  ownReg: PropTypes.object.isRequired,
  cancelRegistration: PropTypes.func.isRequired,
};

export default RegList;
