function findCategory(age, sex, categories) {
  if (!categories) {
    return undefined;
  }
  const options = categories
    .filter((cat) => cat.startsWith(sex))
    .sort().reverse();
  const result = options.find((cat) => {
    const ageGroup = Number(cat.slice(1));
    return !Number.isNaN(ageGroup) && ageGroup <= age;
  });
  return result || options[0];
}

export default findCategory;
