/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './sentry';
import './App.css';
import useData from './hooks/useData';
import EventInfo from './components/EventInfo';
import RegForm from './components/RegForm';
import RegList from './components/RegList';
import useBodyStyle from './hooks/useBodyStyle';

function App() {
  const style = useBodyStyle();
  const data = useData();
  const { error, loading } = data || {};
  if (loading) {
    return (
      <div className="spinner-border text-secondary" role="status" style={style}>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }
  if (error) {
    return (
      <div className="alert alert-danger" style={style}>
        {error.message}
      </div>
    );
  }
  return (
    <div style={style}>
      <EventInfo {...data} />
      <RegForm {...data} />
      <RegList {...data} />
      <footer className="small mb-2">
        <a href="https://runnermaps.nl/" target="_top" className="text-decoration-none">
          <img
            src="https://runnermaps.nl/img/icons/runnermaps16.png"
            width="16"
            height="16"
            alt="RunnerMaps logo"
            className="me-2"
          />
          Powered by RunnerMaps
        </a>
      </footer>
    </div>
  );
}

export default App;
