/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

function EventInfo(props) {
  const { event } = props;
  const {
    title, date, deadline, maxRegs, route,
  } = event;
  const dateStr = format(new Date(date), 'PPPP', { locale: nl });
  const deadlineStr = deadline && format(new Date(deadline), 'EEEE d MMMM.', { locale: nl });
  return (
    <div>
      <h1>
        {`Inschrijving ${title}`}
      </h1>
      <ul className="list-unstyled">
        <li>{`Datum: ${dateStr}`}</li>
        <li>{`Inschrijven tot en met ${deadlineStr}`}</li>
        {Boolean(maxRegs)
          && <li>{`Maximaal ${maxRegs} deelnemers`}</li>}
        {Boolean(route)
          && (
            <li>
              {'Bekijk een '}
              <a href={`https://runnermaps.nl/route/${route}`} target="_blank">
                {' kaart van het parcours '}
                <img src="https://runnermaps.nl/img/icons/runnermaps16.png" alt="" className="ml-2" />
              </a>
            </li>
          )}
      </ul>
    </div>
  );
}

EventInfo.propTypes = {
  event: PropTypes.object.isRequired,
};

export default EventInfo;
