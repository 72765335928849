/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-target-blank */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { endOfDay } from 'date-fns';
import getClub from '../../hooks/getClub';
import { BASEYEAR, CLUBS } from '../../constants';
import FormCheck from './FormCheck';
import findCategory from './findCategory';
import useError from '../../hooks/useError';

const FORM_FIELDS = ['club', 'firstname', 'middlename', 'lastname', 'sex', 'yob', 'distance'];

function RegForm(props) {
  // eslint-disable-next-line no-unused-vars
  const { event, registrations, addRegistration } = props;
  const [cat, setCat] = useState();
  const [errorAlert, setError] = useError();
  const [checkedClub, setCheckedClub] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const club = getClub();
  const clubName = CLUBS[club]?.name;
  const form = useRef();
  const {
    id, deadline, maxRegs, categories, distances,
  } = event;
  if (new Date() > endOfDay(new Date(deadline))) {
    return (
      <div className="alert alert-warning" role="alert">
        Je kunt je niet meer inschrijven, de inschrijftermijn is al verstreken.
      </div>
    );
  }

  if (maxRegs && registrations.length >= maxRegs) {
    return (
      <div className="alert alert-warning" role="alert">
        Je kunt je niet meer inschrijven, het maximale aantal is al bereikt.
      </div>
    );
  }

  function checkCat() {
    const { sex, yob } = form.current.elements;
    if (!sex.value || !yob.value || !yob.validity.valid) {
      setCat('');
      return;
    }
    const age = BASEYEAR - yob.value;
    setCat(findCategory(age, sex.value, categories));
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    const { elements } = form.current;
    const result = { cat, event: id, club };
    FORM_FIELDS.forEach((f) => {
      result[f] = elements[f] && (elements[f].type === 'number' ? elements[f].valueAsNumber : elements[f].value);
    });
    if (result.club === 'other') {
      result.club = elements.otherClub.value;
    }
    addRegistration(result)
      .then(() => setSubmitted(true))
      .catch(setError);
  }

  const otherClub = (
    <input
      type="text"
      className="form-control"
      id="otherClub"
      onChange={() => setCheckedClub('other')}
    />
  );

  if (submitted) {
    return (
      <div className="mb-4">
        {errorAlert}
        <div className="alert alert-success" role="alert">
          Bedankt, je inschrijving is ontvangen!
        </div>
        <div>
          <button type="button" className="btn btn-secondary" onClick={() => setSubmitted(false)}>
            Nog iemand inschrijven
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="mb-4">
      {errorAlert}
      <form onSubmit={handleSubmit} ref={form}>
        {club ? (
          <>
            <div className="alert alert-secondary" role="alert">
              {'Inschrijving via dit formulier is '}
              <b>
                {'alleen voor lopers van '}
                {clubName || club}
                .
              </b>
            </div>
            <input type="hidden" id="club" value={club} />
          </>
        ) : (
          <div className="form-group">
            <div className="form-label">vereniging</div>
            <div>
              {Object.keys(CLUBS).map((key) => (
                <FormCheck
                  key={key}
                  type="radio"
                  name="club"
                  value={key}
                  id={key}
                  label={CLUBS[key].name}
                  checked={checkedClub === key}
                  onChange={() => setCheckedClub(key)}
                  required
                />
              ))}
              <FormCheck
                type="radio"
                name="club"
                value="other"
                id="other"
                label={otherClub}
                onChange={() => setCheckedClub('other')}
                checked={checkedClub === 'other'}
                required
              />
            </div>
          </div>
        )}
        <div className="form-group">
          <label htmlFor="firstname" className="form-label">voornaam</label>
          <input type="text" className="form-control" id="firstname" required />
        </div>
        <div className="form-group">
          <label htmlFor="middlename" className="form-label">tussenvoegsel</label>
          <input type="text" className="form-control" id="middlename" />
        </div>
        <div className="form-group">
          <label htmlFor="lastname" className="form-label">achternaam</label>
          <input type="text" className="form-control" id="lastname" required />
        </div>
        <div className="form-group">
          <div className="form-label">geslacht</div>
          <div>
            <FormCheck type="radio" name="sex" value="M" label="man" onChange={checkCat} required />
            <FormCheck type="radio" name="sex" value="V" label="vrouw" onChange={checkCat} required />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="yob" className="form-label">geboortejaar</label>
          <input type="number" min="1900" max={BASEYEAR} id="yob" onChange={checkCat} required />
        </div>
        <div className="form-group">
          <div className="form-label">categorie</div>
          {cat || '-'}
        </div>
        {distances && (
          <div className="form-group">
            <div className="form-label">afstand</div>
            <div>
              {distances.map((dist) => (
                <FormCheck key={dist} type="radio" name="distance" value={dist} id={dist} label={dist} required />
              ))}
            </div>
          </div>
        )}
        <div className="form-group">
          <div>
            <p className="form-text">
              Door in te schrijven voor deze wedstrijd ga je er mee akkoord dat je gegevens gebruikt
              worden voor het verwerken en publiceren van de uitslagen.
            </p>
            <button type="submit" className="btn btn-primary">Inschrijven</button>
          </div>
        </div>
      </form>
    </div>
  );
}

RegForm.propTypes = {
  event: PropTypes.object.isRequired,
  registrations: PropTypes.array.isRequired,
  addRegistration: PropTypes.func.isRequired,
};

export default RegForm;
