import axios from 'axios';

async function fetchData(eventId, options) {
  const url = `https://test.runnermaps.nl/api/events/${eventId}`;
  try {
    const response = await axios({ url, ...options });
    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return { error };
  }
}

export default fetchData;
