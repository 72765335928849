/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

function FormCheck(props) {
  // eslint-disable-next-line no-unused-vars
  const {
    type, value, label, name, ...rest
  } = props;
  return (
    <div className="form-check">
      <input className="form-check-input" type={type} name={name} value={value} id={value} {...rest} />
      <label className="form-check-label" htmlFor={value}>{label}</label>
    </div>
  );
}

FormCheck.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormCheck;
