import { subMonths } from 'date-fns';

export const CLUBS = {
  AVSPARTA: {
    name: 'AV Sparta',
    style: {
      'font-family': 'Corbel,Arial,Helvetica,sans-serif',
    },
  },
  HAAGATLETIEK: {
    name: 'Haag Atletiek',
    style: {
      color: '#181818',
    },
  },
  HAGUEROADRUNNERS: {
    name: 'The Hague Road Runners',
    style: {
      color: '#777777',
    },
  },
  DEKOPLOPERS: {
    name: 'De Koplopers',
    style: {
      'font-size': '11pt',
    },
  },
};

export const BASEYEAR = subMonths(new Date(), 6).getFullYear();
