import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { BASEYEAR } from '../constants';

function useOwnRegistrations(eventId) {
  const cookieName = `reg-${BASEYEAR}-${eventId}`;
  const [state, setState] = useState({});
  useEffect(() => {
    const cookie = Cookies.get(cookieName);
    if (cookie) {
      setState(JSON.parse(cookie));
    }
  }, []);

  function addReg(id, regCode, expires) {
    const newState = { ...state, [id]: regCode };
    setState(newState);
    const options = {
      expires,
      secure: false,
      sameSite: 'strict',
    };
    Cookies.set(cookieName, JSON.stringify(newState), options);
    return newState;
  }

  return [state, addReg];
}

export default useOwnRegistrations;
