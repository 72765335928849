import { useEffect } from 'react';
import { CLUBS } from '../constants';
import getClub from './getClub';
import getFromSearch from './getFromSearch';

function useBodyStyle() {
  const bgc = getFromSearch('bgc');
  const club = getClub();
  const style = CLUBS[club]?.style;
  useEffect(() => {
    if (bgc) {
      const body = document.querySelector('body');
      body.style.backgroundColor = bgc;
    }
  }, [bgc]);
  return style;
}

export default useBodyStyle;
