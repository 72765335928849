import getFromSearch from './getFromSearch';

function getClubFromHostname() {
  if (!document.referrer || !document.referrer.startsWith('https:')) {
    return '';
  }
  const url = new URL(document.referrer);
  const { hostname } = url;
  return hostname.split('.').reverse()[1].toUpperCase();
}

function getClub() {
  return getFromSearch('club') || getClubFromHostname();
}

export default getClub;
