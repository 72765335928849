import { useEffect, useState } from 'react';
import fetchData from './fetchData';
import getClub from './getClub';
import getFromSearch from './getFromSearch';
import useOwnRegistrations from './useOwnRegistrations';

function useData() {
  const club = getClub();
  const eventId = getFromSearch('ev');
  const [ownReg, addOwnReg] = useOwnRegistrations(eventId);
  const [data, setData] = useState({ loading: true });
  function setRegistrations(registrations) {
    setData((prev) => ({ ...prev, registrations }));
  }
  useEffect(() => {
    fetchData(eventId, { params: { club } }).then(setData);
  }, [eventId, club]);

  async function addRegistration(info) {
    const response = await fetchData(eventId, { method: 'post', data: { club, ...info } });
    const { newRegistration, registrations, error } = response;
    if (error) {
      throw new Error(error.response.data || error.message);
    }
    const { id, regcode } = newRegistration;
    addOwnReg(id, regcode, new Date(data.event.date));
    setRegistrations(registrations);
  }

  async function cancelRegistration(id) {
    const response = await fetchData(eventId, { method: 'delete', data: { id, regcode: ownReg[id], club } });
    const { deletedRows, registrations } = response;
    setRegistrations(registrations);
    return deletedRows;
  }

  return {
    ...data, ownReg, addRegistration, cancelRegistration,
  };
}

export default useData;
