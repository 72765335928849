import React from 'react';
import PropTypes from 'prop-types';

function RegLine(props) {
  const {
    idx, id, cat, firstname, lastname, middlename, distance, regcode, onCancel,
  } = props;
  const listName = [lastname, middlename].filter(Boolean).join(', ');
  function handleCancel() {
    // eslint-disable-next-line no-alert
    if (window.confirm('Inschrijving verwijderen?')) {
      onCancel(id);
    }
  }
  const cancelButton = (
    <button type="button" className="btn btn-link text-danger py-0 px-1 text-decoration-none" onClick={handleCancel}>
      x
    </button>
  );
  return (
    <tr>
      <td className="text-right">{idx + 1}</td>
      <td>{firstname}</td>
      <td>{listName}</td>
      <td className="text-center">{cat}</td>
      <td className="text-center">{distance}</td>
      <td className="text-center py-0">{regcode && cancelButton}</td>
    </tr>
  );
}

RegLine.propTypes = {
  idx: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  cat: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  middlename: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  regcode: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
};

RegLine.defaultProps = {
  regcode: '',
};

export default RegLine;
